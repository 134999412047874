import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import renderBloks from '@renderBloks'
import { Text, H5, MarkdownHandler } from '@system'

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    width: '33.33333%',
    padding: '0px 15px',
    marginBottom: '60px',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  link: {
    textDecoration: 'none',
  },
  innerContainer: {
    border: `1px solid ${theme.palette.border.gray}`,
    padding: '27px 20px',
    '&:hover': {
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.18)',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: '290px',
    },
  },
  logo: {
    marginBottom: '60px',
    height: '98px',
  },
  cardDetails: {
    color: theme.palette.text.tertiary,
    marginBottom: '-18px',
  },
  companyName: {
    fontSize: '14px',
    fontWeight: 600,
  },
  text: {
    fontSize: '14px',
  },
}))

const ChannelPartner = (props) => {
  const { companyName, contactInfo, country, logo, stateProvince, website } =
    props.blok
  const classes = useStyles()

  const CardWrapper = (props) =>
    website ? (
      <a href={website.url} className={classes.link} target="_blank">
        {props.children}
      </a>
    ) : (
      props.children
    )

  return (
    <SbEditable content={props.blok}>
      <Box className={classes.outerContainer}>
        <CardWrapper>
          <Box className={classes.innerContainer}>
            {logo && logo.length > 0 && (
              <Box className={classes.logo}>
                {renderBloks(logo, {
                  style: {
                    width: '100%',
                    maxWidth: '250px',
                    margin: '0 auto',
                    objectFit: 'contain !important',
                    maxHeight: '98px',
                  },
                })}
              </Box>
            )}
            <Box className={classes.cardDetails}>
              {companyName && (
                <H5 className={classes.companyName}>{companyName}</H5>
              )}
              {(stateProvince || country) && (
                <Text className={classes.text}>{`${
                  stateProvince && `${stateProvince}/`
                }${country}`}</Text>
              )}
              {contactInfo && (
                <Text className={classes.text}>
                  <MarkdownHandler>{contactInfo}</MarkdownHandler>
                </Text>
              )}
            </Box>
          </Box>
        </CardWrapper>
      </Box>
    </SbEditable>
  )
}

export default ChannelPartner
